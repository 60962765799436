<template>
	<AccordionKeysForm />
</template>

<script>
import AccordionKeysForm from '@/components/AccordionKeysForm.vue';

export default {
	name: 'ProductDetailAccordionCreate',
	components: {
		AccordionKeysForm,
	},
	data() {
		return {};
	},
};
</script>
